import React from "react";
import { Route, Switch } from "react-router-dom";

import "./App.css";
import Home from "./screens/Home";
import MagicCode from "./screens/MagicCode";

const App = (props) => (
  <Switch>
    <Route path="/:slug" render={() => <MagicCode {...props} />} />
    <Route path="/" render={() => <Home {...props} />} />
  </Switch>
);

export default App;
