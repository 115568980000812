/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import styled from "@emotion/styled";
import { rem } from "polished";

const Page = styled.div`
  width: 100%;
  height: 100vh;
`;

const Content = styled.div`
  width: 100%;
  max-width: ${rem(420)};

  height: 100%;

  margin: 0 auto;

  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.div`
  width: ${rem(96)};
  height: ${rem(96)};

  background: conic-gradient(
    #cd58ff 1%,
    #1c13ff 31%,
    #186dff 50%,
    #ffffff 50%,
    #fbebff 62%,
    #fa80ff 91%,
    #cd58ff 100%
  );
  border-radius: ${rem(16)};

  transform: rotate(90deg);
`;

const H1 = styled.h1`
  font-size: ${rem(36)};
  line-height: ${rem(44)};

  margin: ${rem(36)} 0 ${rem(24)};

  color: #000;
`;

const P = styled.p`
  font-size: ${rem(20)};
  line-height: 1.5;

  color: #000;

  margin: 0 0 ${rem(24)};
`;

const Digits = styled.div`
  display: flex;
  margin-top: ${rem(12)};
`;

const Digit = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;

  width: ${rem(42)};
  height: ${rem(64)};

  font-size: ${rem(34)};
  line-height: 1;
  font-weight: 600;

  background-color: rgba(124, 118, 148, 0.05);
  border-radius: ${rem(8)};

  margin: 0 ${rem(4)};
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: ${rem(20)};
  line-height: 1.5;
`;

const MagicCode = ({ code = "" }) => (
  <>
    <P>
      To finish connecting your Flexa ID to SPEDN, enter this secret code on
      your device:
    </P>
    <Digits>
      {code.split("").map((ch) => (
        <Digit>{ch}</Digit>
      ))}
    </Digits>
    <div
      css={css`
        max-width: ${rem(340)};
        margin: ${rem(84)} auto ${rem(60)};

        padding: ${rem(32)};
        border-radius: ${rem(20)};
        border: ${rem(2)} solid rgba(126, 17, 255, 0.5);
      `}
    >
      <P
        css={css`
          margin: 0 0 ${rem(12)};
          font-weight: 600;
          color: #7e11ff;

          font-size: ${rem(16)};
        `}
      >
        Your security is very important.
      </P>
      <P
        css={css`
          margin: 0;
          font-size: ${rem(16)};
        `}
      >
        Don&apos;t share your secret code with anyone you don&apos;t know
      </P>
    </div>
  </>
);

const Home = (props) => {
  console.log(props);
  const { magicCode, errorMessage } = props;
  console.log("error", errorMessage);
  return (
    <Page>
      <Content
        css={css`
          text-align: center;
        `}
      >
        <Logo />
        <H1>Connect to Flexa</H1>

        {magicCode ? <MagicCode code={magicCode} /> : null}
        {errorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage> : null}
      </Content>
    </Page>
  );
};

export default Home;
