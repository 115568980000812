/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import styled from "@emotion/styled";
import { rem } from "polished";

const Page = styled.div`
  width: 100%;
  height: 100vh;
`;

const Content = styled.div`
  width: 100%;
  max-width: ${rem(420)};

  height: 100%;

  margin: 0 auto;

  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.div`
  width: ${rem(96)};
  height: ${rem(96)};

  background: conic-gradient(
    #cd58ff 1%,
    #1c13ff 31%,
    #186dff 50%,
    #ffffff 50%,
    #fbebff 62%,
    #fa80ff 91%,
    #cd58ff 100%
  );
  border-radius: ${rem(16)};

  transform: rotate(90deg);
`;

const H1 = styled.h1`
  font-size: ${rem(36)};
  line-height: ${rem(44)};

  margin: ${rem(36)} 0 ${rem(24)};

  color: #000;
`;

const P = styled.p`
  font-size: ${rem(20)};
  line-height: 1.5;

  color: #000;

  margin: 0 0 ${rem(24)};
`;

const Home = () => (
  <Page>
    <Content
      css={css`
        text-align: center;
      `}
    >
      <Logo />
      <H1>Connect to Flexa</H1>
      <P>
        You&apos;ve reached this page in error.
        <br />
        See you later!
      </P>
    </Content>
  </Page>
);

export default Home;
