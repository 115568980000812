import React from "react";
import { hydrateRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App";

hydrateRoot(
  document.getElementById("root"),
  <BrowserRouter>
    <App {...window.__INITIAL__DATA__} />
  </BrowserRouter>
);

if (module.hot) {
  module.hot.accept();
}
